// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-gameswithhue-js": () => import("./../../../src/pages/gameswithhue.js" /* webpackChunkName: "component---src-pages-gameswithhue-js" */),
  "component---src-pages-iconoclasm-js": () => import("./../../../src/pages/iconoclasm.js" /* webpackChunkName: "component---src-pages-iconoclasm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stasisrehab-js": () => import("./../../../src/pages/stasisrehab.js" /* webpackChunkName: "component---src-pages-stasisrehab-js" */),
  "component---src-pages-vtts-js": () => import("./../../../src/pages/vtts.js" /* webpackChunkName: "component---src-pages-vtts-js" */)
}

